<template>
  <li @click="select( tab )" :title="text">

    <p :class="{ active }">{{ text }}</p>

  </li> 
</template>

<script>
export default {
  name: 'UserTabs',

  props: {
    tab: String,
    select: Function,
    active: Boolean,
    label: String
  },

  computed: {
    text() {
      return this.label || this.$Utils.Filters.capitalise( this.tab )
    }
  }
}

</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

li {
  width: 100%;
  cursor: pointer;
  position: relative;
  text-align: center;

  //Seperator
  &:not( :first-child ) {
    &::after {
      left: 0;
      top: 10%;
      bottom: 10%;
      height: 80%;
      width: 1px;
      background-color: $off-white;
      content: '';
      position: absolute;
    }
  }

  p {
    font-size: 16px;
    font-family: $font;
    color: $off-white;
    text-align: center;
    padding: 12px 0;
    position: relative;
    height: 100%;
    display: inline-flex;
    min-width: 80px;
    justify-content: center;
    word-break: unset;

    @media ( max-width: $mobile ) {
      min-width: 60px;
      font-size: 14px;
    }

    @media ( max-width: $tiny ) {
      min-width: 60px;
      min-width: unset;
      width: 100%;
    }

    //Active bar
    &.active {
      &::after {
        left: 0;
        bottom: 0;
        right: 0;
        height: 4px;
        width: 100%;
        background-color: $off-white;
        content: '';
        position: absolute;
        @include flex-center;
      }
    }
  }
}
</style>