
<template>
  <div class="user-profile">

    <span class="profile" :alt="name" :style="style" :title="name">
      <svg v-if="icon" v-html="icon"/>

      <span v-if="isMe" @click="trigger" title="Upload image">
        <img :src="icons.camera" alt="Camera"/>

        <!-- Hidden file trigger -->
        <input ref="image" type="file" accept="image/png, image/jpeg" @click="reset" @change="upload"/>
      </span>

    </span>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'UserHeaderProfile',

  props: {
    user: Object
  },

  data() {
    return {
      icons: {
        camera: require( '@/assets/icons/user/camera.svg' )
      }
    }
  },

  computed: {
    ...mapGetters( 'Auth', {
      me: 'id'
    }),

    ...mapGetters( 'Limits', {
      s3: 's3'
    }),

    style() {
      return this.$Utils.User.image( this.user.image, this.user.color )
    },
    
    icon() {
      const icon = this.user.icon
      if ( this.user.image || !icon ) return
      return this.$Utils.User.icon( icon )
    },

    name() {
      return this.$Utils.User.name( this.user )
    },

    isMe() {
      return this.user.id === this.me
    }
  },

  methods: {
    ...mapActions( 'Auth', {
      edit: 'edit'
    }),

    async trigger() {
      this.$refs.image.click()
    },

    async upload( e ) {
      const file = e.target.files[ 0 ]
      const { image: limit } = this.s3
      if ( !file || file.size > limit ) return
      
      this.loading = true
      await this.edit({ image: file })
      this.loading = false
    },

    async reset( e ) {
      e.target.value = null
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

//Profile picture
.user-profile {
  @include flex-config( false, flex-end, flex-start );
  grid-area: profile;

  @media ( max-width: $mobile ) {
    @include flex-center;
  }

  button {
    width: 86px;
  }

  .profile {
    width: 86px;
    min-width: 86px;
    height: 86px;
    @include box-shadow( 0 0 16px 2px rgba( $off-white, $opacity-half ));
    margin: 0;
    cursor: default;
    position: relative;
    overflow: hidden;

    &:hover {
      > span {
        @include flex-center;
      }
    }

    svg {
      width: 32px;
      height: 32px;
    }

    //upload
    > span {
      @include full-stretch;
      background-color: rgba( $white, 0.8 );
      cursor: pointer;
      display: none;
      opacity: 0.85;

      input[ type = file ] { display: none; }

      img {
        width: 32px;
        height: 32px;
      }
    }
  }
}
</style>
