<template>
  <div class="gifts-container">

    <!-- Content -->
    <Gifts v-if="showGifts"/>

    <!-- Error and Loading -->
    <div v-else class="retry">
      <div>

        <h2 v-if="showError" class="bk">{{ error }}</h2>
        <button role="button" v-if="showError" alt="Reload" @click="reload" class="submit circle retry">
          <span class="shadow"></span>
          <span class="edge"></span>
          <span class="content"><img :src="icons.reload" alt="Reload"/></span>
        </button>

        <Loading v-if="loading"/>

      </div>
    </div>
  </div>
</template>

<script>
import Gifts from '@/components/user/gifts/Gifts.vue'
import Loading from '@/components/Loading.vue'

import { mapActions } from 'vuex'

export default {
  name: 'UserGifts',

  data() {
    return {
      loading: false,
      error: null,
      icons: {
        reload: require( '@/assets/icons/shared/reload.svg' )
      }
    }
  },

  components: {
    Gifts,
    Loading
  },

  computed: {
    showGifts() {
      return !this.error && !this.loading
    },

    showError() {
      return this.error && !this.loading
    }
  },

  methods: {
    ...mapActions( 'Giftings', {
      fetch: 'summary'
    }),

    async load() {
      if ( this.loading ) return

      this.error = null
      this.loading = true

      await this.fetch( this.$route.params.id ).catch( e => this.error = e )
      this.loading = false
    },

    async reload() {
      await this.load()
    }
  },

  async mounted() {
    await this.load()
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.gifts-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: $max-width;
  overflow-y: scroll;
  margin: 0 auto;
  padding: $page-padding;
}

button.pagination {
  margin-top: 16px;
}
</style>
