<template>
  <div class="tabs-container">

    <div class="tabs">
      <ul>

        <template v-for="( t, index ) in tabs">
          <Tab v-if="show( t )" :key="index" :tab="t.tab" :label="t.label" :active="t.tab === tab" :select="s => { tab = s }"/>
        </template>

      </ul>
    </div>

    <div class="scroller">

      <Moments v-if="is( 'moments' )" :isMe="isMe"/>
      <Giftings v-if="is( 'giftings' )"/>
      <Gifts v-if="is( 'gifts' )"/>
      <Edit v-if="is( 'edit' )"/>

    </div>
    
  </div> 
</template>

<script>
import Tab from '@/components/user/tabs/Tab.vue'
import Moments from '@/components/user/moments/Container.vue'
import Gifts from '@/components/user/gifts/Container.vue'
import Giftings from '@/components/user/giftings/Container.vue'
import Edit from '@/components/user/edit/Container.vue'

export default {
  name: 'UserTabsContainer',

  props: {
    isMe: Boolean
  },

  data() {
    return {
      tab: 'moments',
      tabs: [{
        tab: 'moments',
        label: 'Photos'
      }, {
        me: true,
        tab: 'giftings',
        label: 'My Gifts'
      }, {
        me: false,
        tab: 'gifts'
      }, {
        me: true,
        tab: 'edit'
      }]
    }
  },

  components: {
    Tab,
    Moments,
    Gifts,
    Giftings,
    Edit
  },

  methods: {
    show( tab ) {
      if ( this.isMe && tab.me !== false ) return true
      if ( !this.isMe && !tab.me ) return true
      return false
    },

    is( tab ) {
      return this.tab === tab
    }
  },

  watch: {
    $route() {
      this.tab = 'moments'
    }
  }
}

</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.tabs {
  width: 100%;
  border-bottom: 1px solid $med-light-grey;
  //background: radial-gradient( ellipse at right bottom, lighten( $primary-color, 6 ) 0%, $primary-color 54%, darken( $primary-color, 6 ) 100% );
  background-color: $primary-color;

  ul {
    width: 100%;
    max-width: $max-width;
    margin: 0 auto;
    @include flex;
    flex-direction: row;
    padding: 0 $page-padding;

    @media ( max-width: $mobile ) {
      padding: 0;
    }
  }

  &-container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    overflow-y: scroll;
    grid-area: content;

    > .scroller {
      overflow-y: scroll;
      text-align: center;
      width: 100%;
      max-width: $max-width;
      margin: 0 auto;
      position: relative;
    }
  }
}
</style>