
<template>
  <li @click="modal = true">

    <button role="button" class="submit circle action" :alt="alt">
      <span class="shadow"></span>
      <span class="edge"></span>
      <span class="content"><img :src="icon" :alt="alt"/></span>
    </button>

    <Send v-if="modal" :them="them" :close="() => modal = false"/>

  </li>
</template>

<script>
import Send from '@/components/user/header/actions/message/Message.vue'

export default {
  name: 'UserActionMessageButton',

  data() {
    return {
      modal: false,
      icon: require( '@/assets/icons/user/actions/message.svg' ),
      alt: 'Message'
    }
  },

  props: {
    them: Number
  },

  components: {
    Send
  }
}
</script>
