<template>
  <li :class="{ active }" @click="choose( gift )" :title="name">

    <div class="gift" :alt="gift.name" :class="gift.name"><svg v-html="icon"/></div>

    <p class="name">{{ name }}</p>
    <p><span><img :src="icons.coin" alt="Coin"/></span>{{ cost }}</p>

  </li>
</template>

<script>
export default {
  name: 'UserActionGift',

  data() {
    return {
      icons: {
        coin: require( '@/assets/icons/shared/coin.svg' )
      }
    }
  },

  props: {
    choose: Function,
    chosen: [ Number, null ],
    gift: Object
  },

  computed: {
    active() {
      return this.chosen === this.gift.id
    },

    icon() {
      try { return require( `!!raw-loader!@/assets/icons/gifts/${ this.gift.name }.svg` ).default } catch ( e ) { console.log( e ) }
      return null
    },

    cost() {
      const g = this.gift
      return g && g.price ? this.$Utils.Filters.abbriviate( g.price ) : null
    },

    name() {
      return this.$Utils.Filters.capitalise( this.gift.name )
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

li {
  cursor: pointer;
  margin: 0;
  padding: 12px 16px;
  display: grid;
  grid-template-rows: auto;
  width: 25%;
  justify-content: center;
  position: relative;

  @media ( max-width: $mobile ) {
    padding: 12px;
  }

  @media ( max-width: $tiny ) {
    width: 33.3%;
  }

  //Icon
  > div {
    opacity: 0.8;
    @include flex-center;

    svg {
      width: 46px;
      height: 46px;

      @media ( max-width: $tablet ) {
        width: 42px;
        height: 42px;
      }

      @media ( max-width: $mobile ) {
        width: 46px;
        height: 46px;
      }
    }
  }

  &:hover, &.active {
    > div {
      opacity: 1;
      transform: scale( 1.05 );
    }
  }

  p {
    font-size: 12px;
    color: $off-black;
    @include flex-center;
    font-weight: bolder;
    position: relative;
    word-break: unset;

    &.name {
      padding: 12px 0 4px 0;
      font-weight: bolder;
    }

    @media ( max-width: $mobile ) {
      font-size: 10px;
    }

    @media ( max-width: $tiny ) {
      font-size: 12px;
    }
    
    span {
      padding-right: 6px;

      img {
        width: 18px;
        height: 18px;

        @media ( max-width: $mobile ) {
          width: 14px;
          height: 14px;
        }

        @media ( max-width: $tiny ) {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}
</style>
