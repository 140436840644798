
<template>
  <li @click="reject" :title="alt">

    <button role="button" @click="reject" :alt="alt" class="submit circle action">
      <span class="shadow"></span>
      <span class="edge"></span>
      <span class="content"><img :src="icon" :alt="alt"/></span>
    </button>

  </li>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'UserActionFriendRejectButton',

  data() {
    return {
      icon: require( '@/assets/icons/user/actions/reject.svg' ),
      alt: 'Reject friend'
    }
  },

  computed: {
    ...mapGetters( 'Friends', {
      friendship: 'friendship'
    })
  },

  methods: {
    ...mapActions( 'Friends', {
      update: 'update'
    }),

    async reject() {
      this.loading = true

      await this.update({ id: this.friendship.id, state: 'REJECTED' })
      
      this.loading = false
    }
  }
}
</script>