<template>
  <div class="modal-container bk" @mousedown.stop="close">
    <form class="user-message modal" @mousedown.stop @submit.prevent="submit">

      <span><h1>Send a message</h1></span>

      <!-- Text -->
      <textarea v-model="text" :maxlength="max"/>
      <p class="remaining">{{ remaining }} Characters remaining</p>

      <div class="buttons">
        <!-- Cancel -->
        <button role="button" alt="Cancel" @click.stop="close" class="submit reject">
          <span class="shadow"></span>
          <span class="edge"></span>
          <span class="content"><p>Cancel</p></span>
        </button>

        <!-- Send -->
        <button role="button" alt="Send" class="submit" :class="{ disabled: !canSend }">
          <span class="shadow"></span>
          <span class="edge"></span>
          <span class="content"><p>Send</p></span>
        </button>
      </div>

    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'UserActionMessageSend',

  data() {
    return {
      error: null,
      loading: false,
      text: ''
    }
  },

  props: {
    close: Function,
    them: Number
  },

  computed: {
    ...mapGetters( 'Auth', {
      me: 'id'
    }),

    ...mapGetters( 'Limits', {
      limit: 'messages'
    }),

    ...mapGetters( 'Conversations', {
      conversations: 'conversations'
    }),

    max() {
      return this.limit.characters
    },

    remaining() {
      return this.max - this.text.length
    },

    canSend() {
      return this.text.trim() && !this.loading
    }
  },

  methods: {
    ...mapActions( 'Conversation', {
      newMessage: 'send'
    }),

    ...mapActions( 'Conversations', {
      newConversation: 'new',
      exists: 'exists'
    }),

    checkStore( participants ) {
      const c = this.conversations.find( c => {
        const ids = c.participants.map( p => p.user )
        return ids.length === participants.length && !this.$_.xor( ids, participants ).length
      })

      return c && c.id
    },

    async send() {
      const participants = [ this.me, this.them ]

      const inStore = this.checkStore( participants )
      if ( Number.isInteger( inStore )) return this.newMessage({ conversation: inStore, text: this.text })

      this.loading = true
      const exists = await this.exists( participants ).catch( e => this.error = e )
      if ( Number.isInteger( exists )) return this.newMessage({ conversation: exists, text: this.text })

      const id = await this.newConversation({ participants }).catch( e => this.error = e )
      if ( id ) return this.newMessage({ conversation: id, text: this.text })

      return
    },

    async submit() {
      if ( !this.canSend ) return
      this.send()
      this.close()
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.user-message {
  grid-template-rows: repeat( 2, auto );
  
  textarea {
    min-height: 76px;
    height: 76px;
    border-radius: 2px;
  }

  .remaining {
    font-size: 16px;
    text-align: left;
  }

  .buttons {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat( 2, 1fr );
  }

  > span {
    text-align: center;
  }

  h1 {
    color: $off-black;
    font-size: 20px;
    position: relative;
    display: inline-block;

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: $off-black;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -2px;
    }
  }
}
</style>
