
<template>
  <div class="details">

    <h1 :title="name">{{ name }}</h1>      

    <p v-if="user.bio">{{ user.bio }}</p>

  </div>
</template>

<script>
export default {
  name: 'UserHeaderDetails',

  props: {
    user: Object
  },

  computed: {
    name() {
      return this.$Utils.User.name( this.user )
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.details {
  grid-area: details;
  @include flex-config( false, center, flex-start );
  flex-direction: column;

  @media ( max-width: $mobile ) {
    @include flex-center;
  }

  h1 {
    font-size: 28px;
    line-height: 26px;
    color: $off-white;
    text-align: left;
    padding: 6px 0;

    @media ( max-width: $tablet ) {
      font-size: 24px;
    }

    @media ( max-width: $mobile ) {
      font-size: 22px;
    }
  }

  p {
    font-size: 14px;
    color: $off-white;
    padding: 6px 0;
    text-align: left;

    @media ( max-width: $mobile ) {
      padding: 0;
      text-align: center;
    }
  }

  button {
    position: absolute;
    top: $page-padding;
    right: $page-padding;
  }
}
</style>
