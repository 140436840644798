
<template>
  <ul class="user-actions">

    <!-- Friendship -->
    <Request v-if="!canAction"/>
    <Accept v-if="canAction"/>
    <Reject v-if="canAction"/>

    <!-- Message -->
    <Message :them="user.id"/>

    <!-- Gifts -->
    <Gifts :them="user"/>

  </ul>
</template>

<script>
import Request from '@/components/user/header/actions/friend/Request.vue'
import Accept from '@/components/user/header/actions/friend/Accept.vue'
import Reject from '@/components/user/header/actions/friend/Reject.vue'
import Message from '@/components/user/header/actions/message/Button.vue'
import Gifts from '@/components/user/header/actions/gifts/Button.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'UserActions',

  props: {
    user: Object
  },

  components: {
    Request,
    Accept,
    Reject,
    Message,
    Gifts
  },

  computed: {
    ...mapGetters( 'Friends', {
      friendship: 'friendship'
    }),

    ...mapGetters( 'Auth', {
      me: 'id'
    }),

    awaiting() {
      return this.$_.get( this.friendship, 'state' ) === 'AWAITING'
    },

    isRequestor() {
      return this.me === this.$_.get( this.friendship, 'requestor' )
    },

    canAction() {
      return this.awaiting && !this.isRequestor
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/_index.scss";

.user-actions {
  width: 100%;
  margin: 0 auto;
  @include flex;
  flex-direction: row;
  grid-area: actions;
  overflow: hidden;
  @include flex-center;
  justify-content: flex-start;
  overflow: visible;

  @media ( max-width: $mobile ) {
    justify-content: center;
  }

  > li {
    margin-right: 10px;

    @media ( max-width: $mobile ) {
      margin: 0 6px;
    }
  }
}
</style>
