<template>
  <form class="user-edit" @click.stop @submit.prevent="submit">

    <div class="header">

      <div>
        <p>Gender</p>
        <Gender :change="gender => this.gender = gender"/>
      </div>

      <div>
        <p>Color</p>
        <Colors :change="color => this.color = color" :selected="color"/>
      </div>

    </div>

    <div>
      <label for="forename">Forename</label>
      <input type="text" name="forename" placeholder="First name" v-model="forename"/>
    </div>

    <div>
      <label for="surname">Surname</label>
      <input type="text" name="surname" placeholder="Surname" v-model="surname"/>
    </div>

    <div>
      <label for="email">Email</label>
      <input type="text" :class="{ invalid: !validEmail }" name="email" placeholder="Email Address" v-model="email"/>
    </div>

    <div>
      <label for="bio">Bio</label>
      <textarea name="bio" v-model="bio" placeholder="Bio"></textarea>
    </div>

    <div class="buttons">
      <button role="button" alt="Save" :class="{ disabled: !canSave }" class="submit">
        <span class="shadow"></span>
        <span class="edge"></span>
        <span class="content"><p>Save</p></span>
      </button>
    </div>

  </form>
</template>

<script>
import Gender from '@/components/user/edit/Gender.vue'
import Colors from '@/components/user/edit/Colors.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'UserEdit',

  data() {
    return {
      loading: false,
      image: null,

      //Fields
      forename: '',
      surname: '',
      email: '',
      bio: '',
      color: null,
      gender: null
    }
  },

  components: {
    Gender,
    Colors
  },

  computed: {
    ...mapGetters( 'Auth', {
      user: 'me'
    }),

    ...mapGetters( 'Limits', {
      s3: 's3'
    }),

    changed() {
      const hasChanged = f => this[ f ] !== this.user[ f ] && this[ f ] !== '' ? f : null
      return [ 'forename', 'surname', 'email', 'bio', 'gender', 'color' ].map( f => hasChanged( f )).filter( Boolean )
    },

    valid() {
      const v = {
        email: e => this.$Utils.Validation.email( e )
      }

      const filter = f => !v[ f ] || v[ f ]( this[ f ])
      return this.changed.filter( filter ).length === this.changed.length
    },

    canSave() {
      return this.changed.length && this.valid
    },

    validEmail() {
      return !this.email || this.$Utils.Validation.email( this.email )
    }
  },

  methods: {
    ...mapActions( 'Auth', {
      edit: 'edit'
    }),

    async submit() {
      if ( !this.canSave ) return
      
      this.loading = true
      let fields = {}
      this.changed.forEach( f => fields[ f ] = this[ f ])
      this.edit( fields )

      this.loading = false
    }
  },

  mounted() {
    const fields = [ 'forename', 'surname', 'email', 'bio', 'color', 'gender' ]
    Object.assign( this, { ...this, ...this.$_.pick( this.user , fields )})
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.user-edit {
  display: grid;
  grid-gap: 16px;
  text-align: left;
  padding: $page-padding;

  textarea {
    height: 80px;
    min-height: 80px;
    border-radius: 2px;
    margin-top: 4px;
  }

  input {
    margin-top: 4px;
  }

  .header {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat( 2, 1fr );
  }

  .buttons {
    @include flex-center;
  }

  label, p {
    font-size: 14px;
    text-align: left;
  }
}
</style>
