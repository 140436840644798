
<template>
  <li @click="add" :title="alt">

    <button role="button" @click="add" :alt="alt" :class="{ unclickable }" class="submit circle action">
      <span class="shadow"></span>
      <span class="edge"></span>
      <span class="content"><img :src="icon" :alt="alt"/></span>
    </button>

  </li>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'UserActionFriendRequestButton',

  data() {
    return {
      loading: false,
      icons: {
        friends: require( '@/assets/icons/user/actions/friends.svg' ),
        reject: require( '@/assets/icons/user/actions/reject.svg' ),
        pending: require( '@/assets/icons/user/actions/pending.svg' ),
        add: require( '@/assets/icons/user/actions/add.svg' )
      }
    }
  },

  computed: {
    ...mapGetters( 'Friends', {
      friendship: 'friendship'
    }),

    ...mapGetters( 'Auth', {
      me: 'id'
    }),

    accepted() {
      return this.$_.get( this.friendship, 'state' ) === 'ACCEPTED'
    },

    rejected() {
      return this.$_.get( this.friendship, 'state' ) === 'REJECTED'
    },

    deleted() {
      return this.$_.get( this.friendship, 'state' ) === 'DELETED'
    },

    isRequestor() {
      return this.me === this.$_.get( this.friendship, 'requestor' )
    },
    
    alt() {
      if ( this.accepted ) return 'Friends'
      if ( this.deleted ) return 'Deleted'
      if ( this.rejected ) return this.isRequestor ? 'Request sent' : 'Rejected'
      return this.friendship ? 'Request sent' : 'Add friend'
    },

    icon() {
      if ( this.accepted ) return this.icons.friends
      if ( this.deleted ) return this.icons.reject
      if ( this.rejected ) return this.isRequestor ? this.icons.pending : this.icons.reject
      return this.friendship ? this.icons.pending : this.icons.add
    },

    id() {
      const id = this.$_.get( this.$route.params, 'id' )
      return isNaN( id ) ? null : parseInt( id )
    },

    unclickable() {
      return this.loading || this.friendship
    }
  },

  methods: {
    ...mapActions( 'Friends', {
      request: 'request'
    }),

    async add() {
      if ( this.unclickable ) return
      this.loading = true

      if ( !this.friendship ) await this.request( this.id )

      this.loading = false
    }
  }
}
</script>