<template>
  <ul>

    <Gift v-for="gift in gifts" :key="gift.id" :gift="gift"/>

  </ul>
</template>

<script>
import Gift from '@/components/user/gifts/Gift.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'UserGiftsList',

  components: {
    Gift
  },

  computed: {
    ...mapGetters( 'Giftings', {
      gifts: 'summary'
    })
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

ul {
  @include flex-center;
  padding: 16px;
  flex-flow: wrap;
  background: $gradient-white;
  border-radius: 4px;
  width: 100%;
  @include box-shadow;
}
</style>