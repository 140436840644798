
<template>
  <li @click="modal = true">

    <button role="button" :alt="alt" class="submit circle action">
      <span class="shadow"></span>
      <span class="edge"></span>
      <span class="content"><img :src="icon" :alt="alt"/></span>
    </button>

    <Gifts v-if="modal" :user="them" :close="() => modal = false"/>

  </li>
</template>

<script>
import Gifts from '@/components/user/header/actions/gifts/Gifts.vue'

export default {
  name: 'UserActionGiftsButton',

  data() {
    return {
      modal: false,
      icon: require( '@/assets/icons/user/actions/gift.svg' ),
      alt: 'Gift'
    }
  },

  props: {
    them: Object
  },

  components: {
    Gifts
  }
}
</script>
