<template>
  <li>

    <router-link :title="user" class="profile" :alt="user" :style="style" :to="{ name: 'users', params: { id: gifting.from }}">
      <svg v-if="icon" v-html="icon"/>
    </router-link>

    <h2 class="tiny">{{ tiny }}</h2>
    <h2 class="short">{{ short }}</h2>
    <h2 class="long">{{ long }}</h2>

    <p>{{ gifting.createdAt | date }}</p>

    <div class="gift" :class="gifting.name" :title="name" :alt="name"><svg v-html="gift"/></div>

  </li>
</template>

<script>
import Filters from '@/utils/filters'

export default {
  name: 'UserGifting',

  filters: {
    ...Filters
  },

  props: {
    gifting: Object
  },

  computed: {
    style() {
      return this.$Utils.User.image( this.gifting.image, this.gifting.color )
    },

    user() {
      return this.$Utils.User.name( this.gifting )
    },
    
    icon() {
      if ( this.gifting.image ) return
      return this.$Utils.User.icon( this.gifting.icon )
    },

    gift() {
      try { return require( `!!raw-loader!@/assets/icons/gifts/${ this.gifting.name }.svg` ).default } catch ( e ) { console.log( e ) }
      return null
    },

    name() {
      return this.$Utils.Filters.capitalise( this.gifting.name )
    },

    tiny() {
      return this.user
    },

    short() {
      return `${ this.user } | ${ this.name }`
    },

    long() {
      const label = this.format( this.gifting )
      return `${ this.user } has sent ${ label }`
    }
  },

  methods: {
    format( gift ) {
      const labels = {
        flowers: 'some Flowers',
        wine: 'Wine'
      }

      return labels[ gift.name ] || `a ${ this.name }`
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

li {
  background-color: $off-white;
  width: 100%;
  border-radius: 4px;
  position: relative;
  @include box-shadow;
  overflow: hidden;
  display: grid;
  grid-template-areas: "image name gift" "image date gift";
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto;
  grid-gap: 8px 16px;
  align-items: center;
  padding: 12px 24px;

  @media ( max-width: $mobile ) {
    padding: 8px 12px;
  }

  &:not( :first-child ) {
    margin-top: 16px;
  }

  > a.profile {
    grid-area: image;

    @media ( max-width: $mobile ) {
      width: 28px;
      height: 28px;
      min-width: 28px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  h2, p {
    @include ellipsis;
    text-align: left;
  }

  h2 {
    grid-area: name;

    @media ( max-width: $tablet ) {
      font-size: 14px;
    }

    @media ( max-width: $mobile ) {
      font-size: 13px;
    }
  }

  p {
    grid-area: date;

    @media ( max-width: $tablet ) {
      font-size: 12px;
    }
  }

  //Icon
  .gift {
    @include flex-center;
    height: 100%;
    grid-area: gift;

    svg {
      width: 48px;
      height: 48px;

      @media ( max-width: $tablet ) {
        width: 42px;
        height: 42px;
      }

      @media ( max-width: $mobile ) {
        width: 28px;
        height: 28px;
      }
    }
  }

  h2.tiny {
    display: none;

    @media ( max-width: $tiny ) {
      display: block;
    }
  }

  h2.short {
    display: none;

    @media ( max-width: $mobile ) {
      display: block;
    }

    @media ( max-width: $tiny ) {
      display: none;
    }
  }

  h2.long {
    @media ( max-width: $mobile ) {
      display: none;
    }
  }
}
</style>
