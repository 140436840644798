
<template>
  <li @click="accept" :title="alt">

    <button role="button" :title="alt" :alt="alt" @click="accept" class="submit circle action">
      <span class="shadow"></span>
      <span class="edge"></span>
      <span class="content"><img :src="icon" :alt="alt"/></span>
    </button>

  </li>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'UserActionFriendAcceptButton',

  data() {
    return {
      icon: require( '@/assets/icons/user/actions/add.svg' ),
      alt: 'Accept friend'
    }
  },

  computed: {
    ...mapGetters( 'Friends', {
      friendship: 'friendship'
    })
  },

  methods: {
    ...mapActions( 'Friends', {
      update: 'update'
    }),

    async accept() {
      this.loading = true

      await this.update({ id: this.friendship.id, state: 'ACCEPTED' })
      
      this.loading = false
    }
  }
}
</script>