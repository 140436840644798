<template>
  <div class="modal-container bk" @mousedown.stop="close" :class="{ hide: insufficient }">
    <form class="feed-send modal" @mousedown.stop @submit.prevent="submit">

      <!-- Image -->
      <div @click="trigger" class="upload" :style="preview" :class="{ hasImage: image }">
        <img v-if="!image" :src="icons.image" alt="Image"/>
        <img v-if="image" @click.stop="image = null" :src="icons.clear" alt="Cross"/>
      </div>

      <!-- Hidden file trigger -->
      <input ref="image" type="file" accept="image/png, image/jpeg" @click="reset" @change="upload"/>

      <!-- Text -->
      <textarea placeholder="Write a caption" v-model="text" :maxlength="max"/>
      <p class="remaining">{{ remaining }} Characters remaining</p>

      <div class="buttons">
        <!-- Cancel -->
        <button role="button" alt="Cancel" @click.stop="close" class="submit reject">
          <span class="shadow"></span>
          <span class="edge"></span>
          <span class="content"><p>Cancel</p></span>
        </button>

        <!-- Post -->
        <button role="button" alt="Post" class="submit" :class="{ disabled: !canPost }">
          <span class="shadow"></span>
          <span class="edge"></span>
          <span class="content"><p>Post</p><span class="coin" v-if="showCoin"><img :src="icons.coin" alt="Coin"/></span><p v-if="showCoin">{{ cost }}</p></span>
        </button>
      </div>
    </form>

    <Insufficient v-if="insufficient" :close="() => this.insufficient = false" :price="this.price"/>

  </div>
</template>

<script>
import Insufficient from '@/components/shop/Insufficient.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'UserMomentsNew',

  data() {
    return {
      loading: false,
      image: null,
      text: '',
      insufficient: false,
      icons: {
        image: require( '@/assets/icons/shared/image.svg' ),
        clear: require( '@/assets/icons/shared/cross.svg' ),
        coin: require( '@/assets/icons/shared/coin.svg' )
      }
    }
  },

  props: {
    close: Function
  },

  components: {
    Insufficient
  },

  computed: {
    ...mapGetters( 'Limits', {
      limit: 'moments',
      s3: 's3'
    }),

    ...mapGetters( 'Prices', {
      price: 'moment'
    }),

    ...mapGetters( 'Auth', {
      balance: 'balance'
    }),

    max() {
      return this.limit.characters
    },

    preview() {
      if ( !this.image ) return
      const preview = URL.createObjectURL( this.image )
      return `background-image: url(${ preview });`
    },

    remaining() {
      return this.max - this.text.length
    },

    canPost() {
      return !this.loading && this.image
    },

    showCoin() {
      return this.cost && this.canPost
    },

    cost() {
      return this.price ? this.$Utils.Filters.abbriviate( this.price ) : null
    }
  },

  methods: {
    ...mapActions( 'Moments', {
      new: 'new'
    }),

    async submit() {
      if ( !this.canPost ) return
      if ( this.price && this.balance < this.price ) return this.insufficient = true
      this.new({ text: this.text, image: this.image })
      this.close()
    },

    async trigger() {
      if ( this.loading || this.image ) return
      this.$refs.image.click()
    },

    async upload( e ) {
      const file = e.target.files[ 0 ]
      const { image: limit } = this.s3
      if ( !file || file.size > limit ) return
      this.image = file
    },

    async reset( e ) {
      e.target.value = null
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.feed-send {
  grid-template-rows: repeat( 3, auto );
  
  textarea {
    border-radius: 2px;
  }

  .remaining {
    font-size: 16px;
    text-align: left;
  }

  .upload {
    width: 100%;
    height: 160px;
    border-radius: 2px;
    cursor: pointer;
    @include flex-center;
    @include box-shadow;
    @include image-center;
    background-color: $white;

    &.hasImage {
      @include flex-config( false, flex-end, flex-start );
      cursor: default;

      img {
        width: 32px;
        height: 32px;
        background-color: rgba( $white, $opacity-low );
        border-radius: 2px;
        padding: 4px;
        cursor: pointer;
        margin: 8px 8px 0 0;
      }
    }

    img {
      width: 48px;
      height: 48px;
    }
  }

  input[ type = file ] { display: none; }

  .buttons {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat( 2, 1fr );
  }
}
</style>
