<template>
  <ul>

    <Gifting v-for="gifting in giftings" :key="gifting.id" :gifting="gifting"/>

  </ul>
</template>

<script>
import Gifting from '@/components/user/giftings/Gifting.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'UserGiftsList',

  components: {
    Gifting
  },

  computed: {
    ...mapGetters( 'Giftings', {
      giftings: 'giftings'
    })
  }
}
</script>