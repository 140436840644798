<template>
  <div class="moment-view-container" @click.stop="close">
    <div class="moment-view" @click.stop>

      <div class="image-container">
        <div class="image" :style="style"></div>
        <img @click="close" :src="icons.clear" alt="Cross"/>
      </div>

      <div class="info">
        <p class="date" :title="moment.createdAt">{{ moment.createdAt | date }}</p>

        <p v-if="moment.text">{{ moment.text }}</p>
      </div>

    </div>
  </div>
</template>

<script>
import Filters from '@/utils/filters'
import { mapActions } from 'vuex'

export default {
  name: 'UserMomentView',

  data() {
    return {
      icons: {
        clear: require( '@/assets/icons/shared/cross.svg' )
      }
    }
  },

  props: {
    moment: Object,
    close: Function
  },

  filters: {
    ...Filters
  },

  computed: {
    ...mapActions( 'Posts', {
      new: 'new'
    }),

    style() {
      return `background-image: url(${ this.moment.image });`
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.moment-view {
  padding: $page-padding;
  position: relative;
  background-color: $off-white;
  border-radius: 4px;
  width: 80%;
  max-width: 600px;

  @media ( max-width: $mobile ) {
    width: 100%;
    border-radius: 0;
    max-width: unset;
  }

  &-container {
    @include full-stretch();
    @include flex-center;
    position: fixed;
    background-color: rgba( $off-black, $opacity-med );
    z-index: 9;
    cursor: default;
  }

  .image {
    @include full-stretch;
    @include image-center;

    &-container {
      width: 100%;
      padding-top: 100%;
      position: relative;

      //Cross
      img {
        position: absolute;
        top: 0;
        right: 0;
        width: 32px;
        height: 32px;
        background-color: rgba( $white, $opacity-low );
        border-radius: 2px;
        padding: 4px;
        cursor: pointer;
        margin: 8px 8px 0 0;
      }
    }
  }

  .info {
    position: relative;
    padding: 12px 0;

    p {
      font-size: 18px;

      &.date {
        font-size: 12px;
        position: absolute;
        color: $med-dark-grey;
        bottom: 0;
        right: 0;
      }
    }
  }
}
</style>
