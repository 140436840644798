<template>
  <div class="user">

    <div class="content" v-if="ready">
      <Header :isMe="isMe"/>
      <Tabs :isMe="isMe"/>
    </div>

    <!-- Error and Loading -->
    <div v-else class="retry">
      <div>

        <h2 v-if="showError" class="bk">{{ error }}</h2>
        <button role="button" alt="Reload" v-if="showError" @click="load" class="submit circle retry">
          <span class="shadow"></span>
          <span class="edge"></span>
          <span class="content"><img :src="icons.reload" alt="Reload"/></span>
        </button>
        <Loading v-if="loading"/>

      </div>
    </div>

  </div>
</template>

<script>
import Header from '@/components/user/header/Container.vue'
import Tabs from '@/components/user/tabs/Container.vue'
import Loading from '@/components/Loading.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Users',

  data() {
    return {
      loading: false,
      error: null,
      icons: {
        reload: require( '@/assets/icons/shared/reload.svg' )
      }
    }
  },

  metaInfo() {
    return {
      title: this.$router.options.title( this.$route )
    }
  },

  components: {
    Loading,
    Header,
    Tabs
  },

  computed: {
    ...mapGetters( 'Auth', {
      me: 'id'
    }),

    id() {
      const id = this.$_.get( this.$route.params, 'id' )
      return isNaN( id ) ? null : parseInt( id )
    },

    isMe() {
      return this.me === this.id || this.id == null
    },

    ready() {
      return !this.loading && !this.error
    },

    showError() {
      return this.error && !this.loading
    }
  },
  
  methods: {
    ...mapActions( 'User', {
      fetch: 'get'
    }),

    ...mapActions( 'Auth', {
      loadMe_: 'me'
    }),

    ...mapActions( 'Friends', {
      getFriendship: 'lookup'
    }),

    ...mapActions( 'Gifts', {
      getGifts: 'get'
    }),

    async load() {
      this.loading = true
      this.error = null

      const fn = this.isMe ? this.loadMe : this.loadThem
      await fn().catch( e => this.error = e )

      this.loading = false
    },

    async loadMe() {
      return Promise.all([
        this.loadMe_()
      ])
    },

    async loadThem() {
      return Promise.all([
        this.fetch( this.id ),
        this.getFriendship( this.id ),
        this.getGifts()
      ])
    }
  },

  watch: {
    $route: {
      immediate: true,
      async handler() {
        await this.load()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.user {
  overflow: hidden;
  
  .content {
    display: grid;
    grid-template-areas: "header" "content";
    grid-template-rows: auto 1fr;
    height: 100%;
    overflow: hidden;
  }
}
</style>