<template>
  <ul class="moments-list">

    <Moment v-for="moment in moments" :key="moment.id" :moment="moment"/>

  </ul>
</template>

<script>
import Moment from '@/components/user/moments/Moment.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'UserMomentsList',

  components: {
    Moment
  },

  computed: {
    ...mapGetters( 'Moments', {
      moments: 'moments'
    })
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.moments-list {
  display: grid;
  grid-template-columns: repeat( 4, 1fr );
  grid-gap: $page-padding;

  @media ( max-width: $tablet ) {
    grid-template-columns: repeat( 3, 1fr );
  }

  @media ( max-width: $mobile ) {
    grid-template-columns: repeat( 2, 1fr );
  }

  @media ( max-width: $tiny ) {
    grid-template-columns: repeat( 1, 1fr );
  }
}
</style>
