<template>
  <div class="giftings-container" ref="scroller" v-on:scroll="scrolling">

    <!-- Content -->
    <List v-if="showGifts"/>

    <!-- Error and Loading -->
    <div v-else class="retry">
      <div>

        <h2 v-if="showError" class="bk">{{ error || 'No gifts' }}</h2>
        <button role="button" v-if="showError" alt="Reload" @click="reload" class="submit circle retry">
          <span class="shadow"></span>
          <span class="edge"></span>
          <span class="content"><img :src="icons.reload" alt="Reload"/></span>
        </button>

        <Loading v-if="loading"/>

      </div>
    </div>

    <button role="button" @click="load" v-if="showLoadMore" alt="Load More" class="pagination" :class="{ loading }">
      <p>{{ label }}</p>
      <img v-if="!loading" :src="icons.load" alt="Load more"/>
    </button>

  </div>
</template>

<script>
import List from '@/components/user/giftings/List.vue'
import Loading from '@/components/Loading.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'UserGiftings',

  data() {
    return {
      loading: false,
      loaded: false,
      canLoadMore: true,
      infScroll: true,
      error: null,
      modal: false,
      icons: {
        load: require( '@/assets/icons/shared/expand.svg' ),
        reload: require( '@/assets/icons/shared/reload.svg' )
      }
    }
  },

  props: {
    isMe: Boolean
  },

  components: {
    List,
    Loading
  },

  computed: {
    ...mapGetters( 'Giftings', {
      isEmpty: 'isEmpty',
      count: 'count'
    }),

    ...mapGetters( 'Limits', {
      limit: 'giftings'
    }),

    ...mapGetters( 'Auth', {
      me: 'id',
      balance: 'balance'
    }),
    
    label() {
      return this.loading ? 'Loading' : 'Load More'
    },

    showGifts() {
      return !this.error && !this.isEmpty && this.loaded
    },

    showLoadMore() {
      return this.canLoadMore && this.showGifts
    },

    showError() {
      return ( this.error || this.isEmpty ) && !this.loading
    },

    offset() {
      return !this.loaded ? 0 : this.count
    },

    scroller() {
      return this.$refs.scroller
    }
  },

  methods: {
    ...mapActions( 'Giftings', {
      fetch: 'get'
    }),

    async load() {
      if ( !this.canLoadMore || this.loading ) return

      this.error = null
      this.loading = true

      const count = await this.fetch({ user: this.me, offset: this.offset }).catch( e => this.error = e )

      if ( !this.loaded && !this.error ) this.loaded = true
      this.loading = false

      const limit = this.limit.pagination
      this.canLoadMore = count === limit || this.error
      
      this.$nextTick(() => {
        this.infScroll = this.$Utils.Scrolling.infinite( this.scroller )
      })
    },

    async reload() {
      this.canLoadMore = true
      await this.load()
    },

    scrolling() {
      const check = this.scroller && this.infScroll && this.canLoadMore && !this.loading
      if ( check && this.$Utils.Scrolling.bottom( this.scroller )) this.load()
    }
  },

  async mounted() {
    await this.load()
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.giftings-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: $max-width;
  overflow-y: scroll;
  margin: 0 auto;
  padding: $page-padding;
}

button.pagination {
  margin-top: 16px;
}
</style>
