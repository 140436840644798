<template>
  <div class="moments-container">
    <div class="moments">

      <!-- Content -->
      <List v-if="showMoments"/>

      <!-- Error and Loading -->
      <div v-else class="retry">
        <div>

          <h2 v-if="showError" class="bk">{{ error || 'No photos' }}</h2>
          <Loading v-if="loading"/>

        </div>
      </div>

      <New v-if="modal" :close="() => modal = false"/>

      <button role="button" @click="load" alt="Load More" v-if="showLoadMore" class="pagination" :class="{ loading }">
        <p>{{ label }}</p>
        <img v-if="!loading" :src="icons.load" alt="Load more"/>
      </button>

    </div>

    <!-- Upload a new photo -->
    <button role="button" alt="New" v-if="showNew" class="submit circle new" @click.stop="modal = true" title="Upload photo">
      <span class="shadow"></span>
      <span class="edge"></span>
      <span class="content"><img :src="icons.new" alt="Plus"/></span>
    </button>

  </div>
</template>

<script>
import List from '@/components/user/moments/List.vue'
import New from '@/components/user/moments/New.vue'
import Loading from '@/components/Loading.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'UserMoments',

  data() {
    return {
      loading: false,
      loaded: false,
      canLoadMore: true,
      error: null,
      modal: false, 
      icons: {
        load: require( '@/assets/icons/shared/expand.svg' ),
        new: require( '@/assets/icons/shared/plus.svg' )
      }
    }
  },

  props: {
    isMe: Boolean
  },

  components: {
    List,
    New,
    Loading
  },

  computed: {
    ...mapGetters( 'Moments', {
      isEmpty: 'isEmpty',
      count: 'count'
    }),

    ...mapGetters( 'Limits', {
      limit: 'moments'
    }),

    ...mapGetters( 'Auth', {
      me: 'id'
    }),
    
    label() {
      return this.loading ? 'Loading' : 'Load More'
    },

    showMoments() {
      return !this.error && !this.isEmpty && this.loaded
    },

    showLoadMore() {
      return this.canLoadMore && this.showMoments
    },

    showError() {
      return ( this.error || this.isEmpty ) && !this.loading
    },

    showNew() {
      return this.loaded && this.isMe //&& !this.modal
    },

    offset() {
      return !this.loaded ? 0 : this.count
    },

    scroller() {
      return this.$refs.scroller
    }
  },

  methods: {
    ...mapActions( 'Moments', {
      fetch: 'get'
    }),

    async load() {
      if ( !this.canLoadMore || this.loading ) return

      this.error = null
      this.loading = true

      const user = this.$route.params.id || this.me
      const count = await this.fetch({ user, offset: this.offset }).catch( e => this.error = e )

      if ( !this.loaded && !this.error ) this.loaded = true
      this.loading = false

      const limit = this.limit.pagination
      this.canLoadMore = count === limit || this.error
    },

    async reload() {
      this.canLoadMore = true
      await this.load()
    }
  },

  //Only need this if the tab is 'me and them' eg Photos/ moments
  //If the user navigates to users/id the photos wont change without this
  watch: {
    $route: {
      immediate: true,
      async handler() {
        this.loading = false
        this.loaded = false
        this.error = null
        this.canLoadMore = true
        await this.load()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.moments {
  position: relative;
  grid-column: 1 / 3;
  grid-row: 1 / 3;
  overflow-y: scroll;
  text-align: center;
  padding: $page-padding;

  &-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr auto;
    max-width: $max-width;
    overflow-y: hidden;
    margin: 0 auto;
  }
}

button.pagination {
  margin-top: 16px;
}
</style>
