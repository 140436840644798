<template>
  <ul class="colors-list" :style="style">

    <li @click="change( color )" :style="background( color )" v-for="color in colors" :key="color" :title="color">
      <span v-if="color === selected"></span>
    </li>

  </ul>
</template>

<script>

export default {
  name: 'UserColors',

  data() {
    return {
      colors: [ '#FFB3BA', '#FFDFBA', '#FFFFBA', '#BAFFC9', '#BAE1FF' ]
    }
  },

  props: {
    change: Function,
    selected: String
  },

  methods: {
    background( color ) {
      return `background-color: ${ color };`
    }
  },

  computed: {
    style() {
      const columns = this.colors.length
      return `grid-template-columns: repeat( ${ columns }, 1fr );`
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.colors-list {
  display: grid;
  border-radius: 4px;
  overflow: hidden;
  @include box-shadow( $shadow );
  margin-top: 4px;

  li {
    cursor: pointer;
    @include flex-center;
    height: 34px;

    span {
      width: 8px;
      height: 8px;
      background-color: $white;
      border: 1px solid $med-light-grey;
      border-radius: 50%;
    }
  }
}
</style>
