
<template>
  <div class="user-header">
    <div class="inner" :class="{ isMe }">

      <Profile :user="user"/>

      <Details :user="user"/>

      <Actions :user="user" v-if="!isMe"/>

    </div>
  </div>
</template>

<script>
import Profile from '@/components/user/header/Profile.vue'
import Details from '@/components/user/header/Details.vue'
import Actions from '@/components/user/header/actions/Container.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'UserHeader',

  data() {
    return {
      loading: false,
      modal: false,
      hide: false
    }
  },

  components: {
    Profile,
    Details,
    Actions
  },

  props: {
    isMe: Boolean
  },

  computed: {
    ...mapGetters( 'Auth', {
      me: 'me'
    }),

    ...mapGetters( 'User', {
      them: 'user'
    }),

    user() {
      return this.isMe ? this.me : this.them
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.user-header {
  font-size: 16px;
  grid-area: header;
  width: 100%;
  text-align: left;
  position: relative;
  grid-area: header;
  background-color: $primary-color;

  //Gradient
  // background: radial-gradient( ellipse at right top, lighten( $primary-color, 6 ) 0%, $primary-color 56%, transparent 100% ),
  //             radial-gradient( ellipse at left, lighten( $primary-color, 7 ) 0%, darken( $primary-color, 6 ), darken( $primary-color, 5 ) 100% );

  .inner {
    margin: 0 auto;
    display: grid;
    grid-gap: 16px 24px;
    grid-template-areas: "profile details" "profile actions";
    grid-template-rows: repeat( 2, auto );
    grid-template-columns: 40% 1fr;
    padding: 16px $page-padding 32px $page-padding;
    max-width: 400px;

    &.isMe {
      grid-template-areas: "profile details";
      grid-template-rows: auto;
      grid-template-columns: auto;
    }

    @media ( max-width: $mobile ) {
      width: unset;
      text-align: center;
      grid-template-areas: "profile" "details" "actions";
      grid-template-rows: repeat( 3, auto );
      grid-template-columns: 1fr;
      grid-gap: 16px;
      padding: 16px $page-padding 24px $page-padding;

      &.isMe {
        grid-template-areas: "profile" "details";
        grid-template-rows: repeat( 2, auto );
      }
    }
  }
}
</style>
