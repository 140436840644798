<template>
  <li :title="name">

    <div class="gift" :alt="gift.name" :class="gift.name"><svg v-html="icon"/></div>

    <p class="name">{{ name }}</p>
    <h2><span>x</span>{{ gift.count }}</h2>

  </li>
</template>

<script>
export default {
  name: 'UserGiftsGift',

  props: {
    gift: Object
  },

  computed: {
    icon() {
      try { return require( `!!raw-loader!@/assets/icons/gifts/${ this.gift.name }.svg` ).default } catch ( e ) { console.log( e ) }
      return null
    },

    name() {
      return this.$Utils.Filters.capitalise( this.gift.name )
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

li {
  margin: 0;
  padding: 8px;
  display: grid;
  grid-template-rows: auto;
  width: 25%;
  justify-content: center;
  position: relative;

  @media ( max-width: $mobile ) {
    width: 33.3%;
  }

  //Icon
  > div {
    @include flex-center;

    svg {
      width: 46px;
      height: 46px;

      @media ( max-width: $mobile ) {
        width: 42px;
        height: 42px;
      }
    }
  }

  p {
    padding-top: 12px;
    text-align: center;
  }

  h2 {
    color: $off-black;
    @include flex-center;
    font-weight: bolder;
    position: relative;

    span {
      padding-right: 2px;
    }

    h2, span {
      font-size: 20px;
      line-height: 20px;
      
      @media ( max-width: $tablet ) {
        font-size: 18px;
        line-height: 18px;
      }

      @media ( max-width: $mobile ) {
        font-size: 16px;
        line-height: 16px;
      }
    }
  }
}
</style>
