<template>
  <li @click.stop="open" :class="{ temp }">

    <div class="image-container">
      <div class="image" :style="style"></div>
    </div>

    <p v-if="moment.text">{{ moment.text }}</p>

    <Modal v-if="modal" :moment="moment" :close="() => modal = false"/>

  </li>
</template>

<script>
import Modal from '@/components/user/moments/View.vue'

export default {
  name: 'UserMoment',

  data() {
    return {
      modal: false
    }
  },

  props: {
    moment: Object
  },

  components: {
    Modal
  },

  methods: {
    open() {
      if ( !this.temp ) this.modal = true
    }
  },

  computed: {
    temp() {
      return this.$Utils.Validation.uuid( this.moment.id )
    },

    style() {
      return `background-image: url(${ this.moment.image });`
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

li {
  background-color: $off-white;
  width: 100%;
  border-radius: 4px;
  position: relative;
  @include box-shadow;
  cursor: pointer;
  padding: 6px;
  overflow: hidden;

  &.temp {
    opacity: $opacity-half;
    pointer-events: none;
  }

  .image {
    @include full-stretch;
    @include image-center;

    &-container {
      width: 100%;
      padding-top: 100%;
      position: relative;
    }
  }

  p {
    margin-top: 6px;
    padding-top: 6px;
    width: 100%;
    border-top: 1px dashed $med-light-grey;
    @include ellipsis;
  }
}
</style>
