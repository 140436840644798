<template>
  <div class="modal-container bk" @mousedown.stop="close" :class="{ hide: insufficient }">
    <form class="user-gifts modal" @mousedown.stop @submit.prevent="submit">

      <div v-if="!loading" class="content">
        <span><h1>Send a gift</h1></span>

        <!-- Gifts -->
        <ul v-if="!loading">
          <Gift v-for="g in gifts" :key="g.id" :gift="g" :chosen="chosen" :choose="choose"/>
        </ul>

        <h2 v-if="gift">{{ confirmation }}</h2>

        <div class="buttons">
          <!-- Cancel -->
          <button role="button" alt="Cancel" @click.stop="close" class="submit reject">
            <span class="shadow"></span>
            <span class="edge"></span>
            <span class="content"><p>Cancel</p></span>
          </button>

          <!-- Send -->
          <button role="button" alt="Send" class="submit" :class="{ disabled: !canSend }">
            <span class="shadow"></span>
            <span class="edge"></span>
            <span class="content"><p>Send</p><span class="coin" v-if="showCoin"><img :src="icons.coin" alt="Coin"/></span><p v-if="showCoin">{{ cost }}</p></span>
          </button>
        </div>
      </div>

      <Loading class="loading" v-if="loading"/>
    </form>

    <Insufficient v-if="insufficient" :close="() => this.insufficient = false" :price="this.price"/>

  </div>
</template>

<script>
import Gift from '@/components/user/header/actions/gifts/Gift.vue'
import Insufficient from '@/components/shop/Insufficient.vue'
import Loading from '@/components/Loading.vue'

import Filters from '@/utils/filters'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'UserActionGifts',

  data() {
    return {
      error: null,
      loading: false,
      gift: null,
      insufficient: false,
      icons: {
        coin: require( '@/assets/icons/shared/coin.svg' )
      }
    }
  },

  components: {
    Gift,
    Insufficient,
    Loading
  },

  props: {
    close: Function,
    user: Object
  },

  filters: {
    ...Filters
  },

  computed: {
    ...mapGetters( 'Gifts', {
      gifts: 'gifts'
    }),

    ...mapGetters( 'Auth', {
      balance: 'balance'
    }),

    canSend() {
      return this.gift && !this.loading
    },

    showCoin() {
      return this.cost && this.canSend
    },

    chosen() {
      return this.$_.get( this.gift, 'id' )
    },

    price() {
      return this.$_.get( this.gift, 'price' )
    },

    name() {
      return this.$Utils.User.name( this.user )
    },

    cost() {
      return this.price ? this.$Utils.Filters.abbriviate( this.price ) : null
    },

    confirmation() {
      if ( !this.gift ) return
      const label = this.format( this.gift )
      return `Sending ${ label } to ${ this.name }`
    }
  },

  methods: {
    ...mapActions( 'Gifts', {
      getGifts: 'get'
    }),

    ...mapActions( 'Giftings', {
      new: 'new'
    }),

    async send() {
      const args = { gift: this.gift.id, user: this.user.id }
      await this.new( args ).catch( e => this.error = e )
    },

    async submit() {
      if ( !this.canSend ) return
      if ( this.price && this.balance < this.price ) return this.insufficient = true

      this.send()
      this.close()
    },

    choose( gift ) {
      this.gift = this.chosen === gift.id ? null : gift
    },

    format( gift ) {
      const labels = {
        flowers: 'some Flowers',
        wine: 'Wine'
      }

      return labels[ gift.name ] || `a ${ this.$Utils.Filters.capitalise( gift.name )}`
    }
  },

  async mounted() {
    if ( this.gifts.length ) return

    this.loading = true
    await this.getGifts()
    this.loading = false
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.user-gifts {
  @include flex-center;

  > .content {
    grid-template-rows: auto;
    width: 100%;
    display: grid;
    grid-gap: 16px;

    ul {
      @include flex-center;
      padding-bottom: 16px;
      flex-flow: wrap;
    }

    .buttons {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat( 2, 1fr );
    }

    > span {
      text-align: center;
    }

    h1 {
      color: $off-black;
      font-size: 20px;
      position: relative;
      display: inline-block;

      &::after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: $off-black;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2px;
      }
    }
  }

  .loading {
    @include full-stretch;
  }
}
</style>
