<template>
  <div @click.stop class="user-gender" :class="state">

    <!-- Male -->
    <div class="male" :class="{ active: state === 'MALE' }" @click.stop="update( 'MALE' )" title="Male">
      <svg title="Male" height="384pt" viewBox="0 0 384 384" width="384pt" xmlns="http://www.w3.org/2000/svg"><path d="m383.792969 13.9375c-.175781-1.378906-.480469-2.707031-.984375-3.953125-.015625-.03125-.015625-.074219-.023438-.113281 0-.007813-.007812-.015625-.015625-.023438-.554687-1.3125-1.3125-2.503906-2.167969-3.609375-.210937-.261719-.417968-.519531-.640624-.765625-.914063-1.03125-1.90625-1.984375-3.058594-2.761718-.03125-.023438-.070313-.03125-.101563-.054688-1.113281-.734375-2.34375-1.289062-3.632812-1.726562-.320313-.113282-.632813-.210938-.960938-.296876-1.351562-.367187-2.742187-.632812-4.207031-.632812h-112c-8.832031 0-16 7.167969-16 16s7.167969 16 16 16h73.367188l-95.496094 95.496094c-25.464844-20.367188-56.816406-31.496094-89.871094-31.496094-79.398438 0-144 64.601562-144 144s64.601562 144 144 144 144-64.601562 144-144c0-33.039062-11.121094-64.382812-31.503906-89.871094l95.503906-95.503906v73.375c0 8.832031 7.167969 16 16 16s16-7.167969 16-16v-112c0-.335938-.078125-.65625-.097656-.984375-.023438-.367187-.0625-.71875-.109375-1.078125zm-239.792969 338.0625c-61.761719 0-112-50.238281-112-112s50.238281-112 112-112c29.902344 0 58.054688 11.640625 79.222656 32.734375 21.136719 21.210937 32.777344 49.363281 32.777344 79.265625 0 61.761719-50.238281 112-112 112zm0 0"/></svg>
    </div>

    <!-- N/A -->
    <div class="na" :class="{ active: !state }" @click.stop="update()"><p>N/ A</p></div>

    <!-- Female -->
    <div class="female" :class="{ active: state === 'FEMALE' }" @click.stop="update( 'FEMALE' )" title="Female">
      <svg title="Female" height="384pt" viewBox="-56 0 384 384" width="384pt" xmlns="http://www.w3.org/2000/svg"><path d="m272 136c0-74.992188-61.007812-136-136-136s-136 61.007812-136 136c0 69.566406 52.535156 127.015625 120 134.976562v33.023438h-32c-8.832031 0-16 7.167969-16 16s7.167969 16 16 16h32v32c0 8.832031 7.167969 16 16 16s16-7.167969 16-16v-32h32c8.832031 0 16-7.167969 16-16s-7.167969-16-16-16h-32v-33.023438c67.464844-7.960937 120-65.410156 120-134.976562zm-240 0c0-57.34375 46.65625-104 104-104s104 46.65625 104 104-46.65625 104-104 104-104-46.65625-104-104zm0 0"/></svg>
    </div>

  </div>
</template>

<script>
export default {
  name: 'UserGender',

  data() {
    return {
      state: null
    }
  },

  props: {
    gender: [ String, null ],
    change: Function
  },

  methods: {
    update( value = null ) {
      this.state = value
      if ( this.change ) this.change( value )
    }
  },

  mounted() {
    this.state = this.gender
  }
}

</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.user-gender {
  position: relative;
  display: grid;
  grid-template-columns: repeat( 3, 1fr );
  grid-template-rows: auto;
  width: 100%;
  margin: 0 auto;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  @include box-shadow( $shadow );
  background-color: $white;
  margin-top: 4px;

  div {
    @include flex-center;
    flex-direction: column;
    grid-column: 2;
    grid-row: 1;
    padding: 8px;

    &:hover {
      background-color: rgba( $hover, $opacity-high );

      svg {
        transform: scale( 1.075 );
      }
    }

    &.active {
      background-color: rgba( $hover, $opacity-low );
    }

    &:hover, &.active {
      svg {
        $c: rgba( $white, 0.8 );
        $bs: inset 0 0 12px 2px $c, 0 0 12px 4px $c;
        @include box-shadow( $bs );
      }
    }

    &.male {
      grid-column: 1;
      grid-row: 1;

      svg {
        fill: $gender-male;
      }
    }

    &.female {
      grid-column: 3;
      grid-row: 1;

      svg {
        fill: $gender-female;
      }
    }

    &.na {
      position: relative;

      &::before, &::after {
        content: '';
        bottom: 8px;
        top: 8px;
        width: 1px;
        background-color: $med-light-grey;
        position: absolute;
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }

    svg {
      width: 16px;
      height: 16px;
      fill: $dark-grey;
    }
  }

  .highlight {
    @include box-shadow( 0 0 0 1px $med-dark-grey );
    border-radius: 50%;
    pointer-events: none;

    &-container {
      padding: 4px;
    }
  }
}
</style>